$( document ).ready( function() {
	/* - - - - - - - - - - - - - -       initial scrollbar       - - - - - - - - - - - - - - - */
	$( '.js-scrollbar' ).mCustomScrollbar({
		autoDraggerLength: false,
	});

	/* - - - - - - - - - - - - - -       sidebar toggle       - - - - - - - - - - - - - - - */
	function setSidebar() {
		if ( window.matchMedia( '(max-width: 1199px)' ).matches ) {
			$( '.menu-toggle' ).removeClass( 'active' );
			$( '.main' ).addClass( 'sidebar-hide' );
		} else {
			$( '.menu-toggle' ).addClass( 'active' );
			$( '.main' ).removeClass( 'sidebar-hide' );
		}
		$( '.overflow' ).removeClass( 'menu-show' );
	}
	setSidebar();
	$( window ).on( 'resize', function() {
		setSidebar();
	});
	$( document ).on( 'click', '.menu-toggle', function() {
		if ( !$( this ).hasClass( 'active' ) ) {
			$( this ).addClass( 'active' );
			$( '.main' ).removeClass( 'sidebar-hide' );
			if ( window.matchMedia( '(max-width: 1199px)' ).matches ) {
				$( '.overflow' ).addClass( 'menu-show' );
			}
		} else {
			$( this ).removeClass( 'active' );
			$( '.main' ).addClass( 'sidebar-hide' );
			$( '.overflow' ).removeClass( 'menu-show' );
		}
	});
	$( document ).on( 'click', '.menu-close', function() {
		$( '.menu-toggle' ).removeClass( 'active' );
		$( '.main' ).addClass( 'sidebar-hide' );
		$( '.overflow' ).removeClass( 'menu-show' );
	});
	$( document ).on( 'click', function( e ) {
		if ( !$( e.target ).closest( '.menu-toggle, .main-sidebar' ).length ) {
			if ( window.matchMedia( '(max-width: 1199px)' ).matches ) {
				$( '.menu-toggle' ).removeClass( 'active' );
				$( '.main' ).addClass( 'sidebar-hide' );
				$( '.overflow' ).removeClass( 'menu-show' );
			}
		}
		e.stopPropagation();
	});

	/* - - - - - - - - - - - - - -       header lang toggle       - - - - - - - - - - - - - - - */
	$( document ).on( 'click', '.header-lang__head', function() {
		const $parent = $( this ).closest( '.header-lang' );
		if ( !$parent.hasClass( 'show' ) ) {
			$parent.addClass( 'show' );
			$parent.find( '.header-lang__list' ).slideDown( 300 );
		} else {
			$parent.removeClass( 'show' );
			$parent.find( '.header-lang__list' ).slideUp( 300 );
		}
	});
	function setLangPosition() {
		const $this = $( '.sidebar-lang' );
		$this.detach();
		if ( window.matchMedia( '(max-width: 1199px)' ).matches ) {
			$( '.sidebar' ).append( $this );
		} else {
			$( '.header-settings' ).prepend( $this );
		}
	}
	if ( $( '.sidebar-lang' ).length ) {
		setLangPosition();
		$( window ).on( 'resize', function() {
			setLangPosition();
		});
	}

	/* - - - - - - - - - - - - - -       sidebar menu toggle       - - - - - - - - - - - - - - - */
	$( document ).on( 'click', '.sidebar-menu ul li a', function( e ) {
		const $parent = $( this ).closest( 'li' );
		if ( $parent.find( 'ul' ).length ) {
			e.preventDefault();
			if ( !$parent.hasClass( 'show' ) ) {
				$parent.addClass( 'show' );
				$parent.find( 'ul' ).slideDown( 300 );
			} else {
				$parent.removeClass( 'show' );
				$parent.find( 'ul' ).slideUp( 300 );
			}
		}
	});

	/* - - - - - - - - - - - - - -       sidebar lang toggle       - - - - - - - - - - - - - - - */
	$( document ).on( 'click', '.sidebar-lang__box', function( e ) {
		if ( !$( this ).hasClass( 'show' ) ) {
			$( this ).addClass( 'show' );
			$( this ).find( '.sidebar-lang__box-list' ).slideDown( 200 );
		} else {
			$( this ).removeClass( 'show' );
			$( this ).find( '.sidebar-lang__box-list' ).slideUp( 300 );
		}
	});

	/* - - - - - - - - - - - - - -       sidebar theme toggle       - - - - - - - - - - - - - - - */
	$( document ).on( 'click', '.sidebar-theme a', function( e ) {
		e.preventDefault();
		$( '.sidebar-theme a' ).removeClass( 'active' );
		$( this ).addClass( 'active' );
		if ( $( this ).attr( 'data-theme' ) === 'light' ) {
			$( 'body' ).addClass( 'light' );
		} else {
			$( 'body' ).removeClass( 'light' );
		}
	});

	/* - - - - - - - - - - - - - -       message close       - - - - - - - - - - - - - - - */
	$( document ).on( 'click', '.message-close', function() {
		$( this ).closest( '.message' ).addClass( 'hide' );
	});

	/* - - - - - - - - - - - - - -       tabs toggle       - - - - - - - - - - - - - - - */
	$( document ).on( 'click', '.tabs li', function() {
		if ( !$( this ).hasClass( 'active' ) ) {
			$( this ).closest( '.tabs' ).find( 'li' ).removeClass( 'active' );
			$( this ).addClass( 'active' );
		}
	});

	/* - - - - - - - - - - - - - -       select toggle       - - - - - - - - - - - - - - - */
	$( document ).on( 'click', '.form-select__toggle', function() {
		const $parent = $( this ).closest( '.form-select__box' );
		$( '.form-select__box' ).not($parent).css( 'z-index', '1' );
		setTimeout( function() {
			$( '.form-select__box' ).not($parent).css( 'z-index', '' );
		}, 300 );
		$( '.catalog-filter' ).addClass( 'active' );
		if ( !$parent.hasClass( 'show' ) ) {
			$parent.css( 'z-index', 2 );
			$( '.form-select__box' ).removeClass( 'show' );
			$parent.addClass( 'show' );
			$( '.form-select__list' ).slideUp( 300 );
			$parent.find( '.form-select__list' ).slideDown( 300 );
		} else {
			$parent.css( 'z-index', 1 );
			$parent.removeClass( 'show' );
			$parent.find( '.form-select__list' ).slideUp( 300 );
			setTimeout( function() {
				$parent.css( 'z-index', '' );
			}, 300 );
			setTimeout( function() {
				$( '.catalog-filter' ).removeClass( 'active' );
			}, 150 );
		}
	});
	$( document ).on( 'click', '.form-select:not(.select-multiple) .form-select__list li', function() {
		const $this = $( this ),
			$parent = $( this ).closest( '.form-select__box' ),
			$title = $parent.find( '.form-select__title' );
		setTimeout( function() {
			$( '.catalog-filter' ).removeClass( 'active' );
		}, 150 );
		if ( !$this.hasClass( 'active' ) ) {
			$parent.css( 'z-index', 1 );
			setTimeout( function() {
				$parent.css( 'z-index', '' );
			}, 300 );
			$parent.find( '.form-select__list li' ).removeClass( 'active' );
			$this.addClass( 'active' );
			$title.html( $this.html() );
			$parent.removeClass( 'show' );
			$parent.find( '.form-select__list' ).slideUp( 300 );
		}
	});
	$( document ).on( 'click', '.form-select.select-multiple .form-select__list li', function() {
		const $this = $( this ),
			$parent = $( this ).closest( '.form-select__box' ),
			$title = $parent.find( '.form-select__title' );
		$parent.css( 'z-index', 1 );
		setTimeout( function() {
			$parent.css( 'z-index', '' );
		}, 300 );
		$parent.removeClass( 'show' );
		$parent.find( '.form-select__list' ).slideUp( 300 );
		setTimeout( function() {
			$( '.catalog-filter' ).removeClass( 'active' );
		}, 150 );
		if ( !$this.hasClass( 'active' ) ) {
			if ( $this.hasClass( 'form-select__all' ) ) {
				$parent.find( '.form-select__list li' ).removeClass( 'active' );
				$this.addClass( 'active' );
				$title.html( $this.html() );
			} else {
				$parent.find( '.form-select__list .form-select__all' ).removeClass( 'active' );
				$this.addClass( 'active' );
				if ( !$title.find( '.form-select__tag' ).length ) {
					$title.text( '' );
				}
				$title.append( '<span class="form-select__tag" data-tag="' + $( this ).attr( 'data-tag' ) + '">' + $this.html() + '<span class="icon icon-i-close"></span></span>' );
			}
		} else {
			$this.removeClass( 'active' );
			$title.find( '.form-select__tag[data-tag="' + $( this ).attr( 'data-tag' ) + '"]' ).remove();
			if ( $title.text() === '' ) {
				$parent.find( '.form-select__list .form-select__all' ).addClass( 'active' );
				$title.html( $parent.find( '.form-select__list .form-select__all' ).html() );
			}
		}
	});
	$( document ).on( 'click', '.form-select__tag', function() {
		const $this = $( this ),
			$parent = $( this ).closest( '.form-select__box' ),
			$title = $parent.find( '.form-select__title' );
		$this.remove();
		$parent.find( '.form-select__list li[data-tag="' + $this.attr( 'data-tag' ) + '"]' ).removeClass( 'active' );
		if ( $title.text() === '' ) {
			$parent.find( '.form-select__list .form-select__all' ).addClass( 'active' );
			$title.html( $parent.find( '.form-select__list .form-select__all' ).html() );
		}
	});
	$( document ).on( 'mouseenter', '.form-select .form-select__list li', function() {
		const $this = $( this ),
			$parent = $this.closest( '.form-select__box' ),
			$line = $parent.find( '.form-select__line' );
		let top = $this.position().top + 40;
		if ( $this.closest( '.mCSB_container' ) ) {
			top = top + $this.closest( '.mCSB_container' ).position().top;
		}
		$line.css( 'top', top );
	});
	$( document ).on( 'mouseleave', '.form-select .form-select__list', function() {
		const $parent = $( this ).closest( '.form-select__box' ),
			$line = $parent.find( '.form-select__line' );
		$line.css( 'top', '' );
	});
	$( document ).on( 'click', function( e ) {
		if ( !$( e.target ).closest( '.form-select' ).length ) {
			$( '.form-select__box' ).css( 'z-index', '1' );
			$( '.form-select__box' ).removeClass( 'show' );
			$( '.form-select__list' ).slideUp( 300 );
			setTimeout( function() {
				$( '.form-select__box' ).css( 'z-index', '' );
			}, 300 );
			setTimeout( function() {
				$( '.catalog-filter' ).removeClass( 'active' );
			}, 150 );
		}
		e.stopPropagation();
	});

	/* - - - - - - - - - - - - - -       tag switch       - - - - - - - - - - - - - - - */
	$( '.tag-switch' ).each( function() {
		const $this = $( this ),
			$switch = $this.find( '[type="checkbox"]' ),
			$title = $this.find( '.tag-title' );
		if ( $switch.prop( 'checked' ) ) {
			$this.removeClass( 'inactive' );
			$title.text( $this.attr( 'data-tag-on' ) );
		} else {
			$this.addClass( 'inactive' );
			$title.text( $this.attr( 'data-tag-off' ) );
		}
	});
	$( document ).on( 'click', '.tag-switch', function() {
		const $this = $( this ),
			$switch = $this.find( '[type="checkbox"]' ),
			$title = $this.find( '.tag-title' );
		if ( $switch.prop( 'checked' ) ) {
			$this.addClass( 'inactive' );
			$switch.prop( 'checked', false );
			$title.text( $this.attr( 'data-tag-off' ) );
		} else {
			$this.removeClass( 'inactive' );
			$switch.prop( 'checked', true );
			$title.text( $this.attr( 'data-tag-on' ) );
		}
	});

	/* - - - - - - - - - - - - - -       catalog mouse       - - - - - - - - - - - - - - - */
	$( document ).on( 'mouseenter', '.catalog-item:not(.show) .catalog-item__box', function( e ) {
		$( '.cursor' ).show( 100 );
	});
	$( document ).on( 'mouseleave', '.catalog-item__box', function() {
		$( '.cursor' ).hide( 100 );
	});
	$( document ).on( 'mousemove', '.catalog-item__box', function( e ) {
		const posTop = e.clientY,
			posLeft = e.clientX;
		$( '.cursor' ).css({
			'top': posTop,
			'left': posLeft,
		});
	});

	/* - - - - - - - - - - - - - -       blog tabs       - - - - - - - - - - - - - - - */
	$( document ).on( 'click', '.blog-tabs li', function() {
		const $this = $( this );
		if ( !$this.hasClass( 'active' ) ) {
			$( '.blog-tabs li' ).removeClass( 'active' );
			$this.addClass( 'active' );
			$( '.blog-list' ).removeClass( 'show' );
			$( '.blog-list[data-tab="' + $this.attr( 'data-tab' )  + '"]' ).addClass( 'show' );
		}
	});

	/* - - - - - - - - - - - - - -       init select       - - - - - - - - - - - - - - - */
	$( document ).on( 'click', '.js-select-modal', function() {
		$( '.overflow' ).addClass( 'show' );
		$( '.select-modal' ).addClass( 'show' );
		$( '.modal' ).removeClass( 'show' );
	});
	$( document ).on( 'click', '.select-modal__close, .overflow', function() {
		$( '.overflow' ).removeClass( 'show' );
		$( '.select-modal' ).removeClass( 'show' );
	});
	$( document ).on( 'input', '.select-modal__search input', function() {
		const $this = $( this ),
			$modal = $this.closest( '.select-modal' ),
			$list = $modal.find( '.select-modal__list' ),
			$empty = $modal.find( '.select-modal__empty' ),
			val = $this.val().toLowerCase();
		let isEmpty = true;
		$list.find( '.select-modal__box' ).each(function() {
			const $box = $( this ),
				str = $box.find( '.select-modal__box-title' ).text().toLowerCase();
			if ( ~str.indexOf( val ) ) {
				$box.removeClass( 'd-none' );
				isEmpty = false;
			} else {
				$box.addClass( 'd-none' );
			}
		});
		if ( isEmpty ) {
			$empty.addClass( 'show' );
		} else {
			$empty.removeClass( 'show' );
		}
	});
	$( document ).on( 'click', '.select-modal__box', function() {
		const $modal = $( this ).closest( '.select-modal' ),
			$search = $modal.find( '.select-modal__search' );
		$search.find( 'input' ).val( '' );
		$modal.find( '.select-modal__box' ).removeClass( 'd-none' );
		$( '.overflow' ).removeClass( 'show' );
		$( '.select-modal' ).removeClass( 'show' );
	});

	$( document ).on( 'click', '[data-modal]', function( e ) {
		e.preventDefault();
		const src = $( this ).attr( 'data-src' );
		$( src ).addClass( 'show' );
		// $( '.overflow' ).addClass( 'show' );
	});
	$( document ).on( 'click', '.modal-close, .overflow, [data-close]', function(e) {
		e.preventDefault();
		$( '.modal' ).removeClass( 'show' );
		$( '.overflow' ).removeClass( 'show' );
	});
	$( document ).on( 'click', function( e ) {
		if ( !$( e.target ).closest( '.modal-box, [data-modal]' ).length ) {
			$( '.modal' ).removeClass( 'show' );
		}
		e.stopPropagation();
	});

	/* - - - - - - - - - - - - - -       content item toggle       - - - - - - - - - - - - - - - */
	$( document ).on( 'click', '.justify-content-item-toggle', function( e ) {
		const $this = $( this ),
			$parent = $this.closest( '.catalog-item' );
		if ( $( e.target ).attr( 'data-modal' ) === undefined ) {
			if ( !$parent.hasClass( 'show' ) ) {
				$( '.catalog-item' ).removeClass( 'show' );
				$parent.addClass( 'show' );
				$( '.catalog-item__content' ).slideUp( 300 );
				$parent.find( '.catalog-item__content' ).slideDown( 300 );
				$( '.cursor' ).hide( 100 );
			} else {
				$parent.removeClass( 'show' );
				$parent.find( '.catalog-item__content' ).slideUp( 300 );
			}
		}
	});

	$( document ).on( 'click', '.catalog-toogleButton', function() {
		var $toggleText = $(this).data('text');
		var $buttonText = $(this).find('.text').text();
		$(this).find('.text').html($toggleText);
		$(this).data('text', $buttonText);
		$(this).toggleClass('active');
		$('.catalog-filter').stop(true, false).slideToggle(600);
	});
	$( document ).on( 'click', '.catalog-filter__toggleButton', function() {
		var $btn = $('.catalog-toogleButton');
		var $toggleText = $btn.data('text');
		var $buttonText = $btn.find('.text').text();
		$btn.find('.text').html($toggleText);
		$btn.data('text', $buttonText);
		$btn.toggleClass('active');
		$('.catalog-filter').stop(true, false).slideToggle(600);
	});
	$( window ).on( 'resize', function() {
		if ( window.matchMedia( '(min-width: 768px)' ).matches ) {
			$('.catalog-filter').css( 'display', '' );
		}
	});

	// $( document ).on( 'mouseenter', '.info', function() {
	// 	const $this = $( this );
	// 	const windowWidth = $( window ).innerWidth(),
	// 		width = 200;
	// 	if ( $this.offset().left + width > windowWidth  ) {
	// 		$this.find( '.info-text' ).addClass( 'left' );
	// 	} else {
	// 		$this.find( '.info-text' ).removeClass( 'left' );
	// 	}
	// });
});
